import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/Users/admin/IdeaProjects/credit-ms-web/node_modules/core-js/modules/es6.array.iterator.js";
import "/Users/admin/IdeaProjects/credit-ms-web/node_modules/core-js/modules/es6.promise.js";
import "/Users/admin/IdeaProjects/credit-ms-web/node_modules/core-js/modules/es6.object.assign.js";
import "/Users/admin/IdeaProjects/credit-ms-web/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from "vue";
import "normalize.css/normalize.css"; // a modern alternative to CSS resets
import Element from "element-ui";
import "./styles/element-variables.scss";
import "@/styles/index.scss"; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import _i18n from "./lang"; // internationalization
import "./icons"; // icon
import "./utils/error-log"; // error log
import request from "@/utils/request";
import * as filters from "./filters"; // global filters
import "@/styles/common.scss";
import { hasPermission, hasNoPermission, hasAnyPermission } from "./utils/permissionDirect";
var Plugins = [hasPermission, hasNoPermission, hasAnyPermission];
import VueQuillEditor from "vue-quill-editor";
// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { getWeek, goContractPage, copyText, translateDict, yuanToFen, fenToYuan, checkProInstActionDate, checkInstActionDate, getLoanBranchCount, numToPercent } from "@/utils";
Vue.use(VueQuillEditor);
Plugins.map(function (plugin) {
  Vue.use(plugin);
});
Vue.use(Element, {
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  },
  size: "default"
});
Vue.prototype.$post = request.post;
Vue.prototype.$get = request.get;
Vue.prototype.$get2 = request.get2;
Vue.prototype.$put = request.put;
Vue.prototype.$delete = request.delete;
Vue.prototype.$download = request.download;
Vue.prototype.$postDownloadExcel = request.postDownloadExcel;
Vue.prototype.$getDownloadExcel = request.getDownloadExcel;
Vue.prototype.$upload = request.upload;
Vue.prototype.$login = request.login;
Vue.prototype.$specialMethods = request.specialMethods;
Vue.prototype.$getWeek = getWeek; // 日期转周
Vue.prototype.$goContractPage = goContractPage; // 路由跳转
Vue.prototype.$copyText = copyText; // 拷贝
Vue.prototype.$translateDict = translateDict; // 字典翻译
Vue.prototype.$getLoanBranchCount = getLoanBranchCount; // 计算借款的店铺数量
Vue.prototype.$yuanToFen = yuanToFen; // 元转分
Vue.prototype.$fenToYuan = fenToYuan; //分转元
Vue.prototype.$checkProInstActionDate = checkProInstActionDate; // 违规提示
Vue.prototype.$checkInstActionDate = checkInstActionDate; // 违规提示
Vue.prototype.$numToPercent = numToPercent; // 违规提示

// register global utility filters
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
// console.log(Vue.prototype, "Vue.prototype");
// Vue.prototype.$ELEMENT = {
//   size: "default",
//   input: {
//     size: "mini !important",
//   },
//   select: {
//     size: "mini !important",
//   },
//   datePicker: {
//     size: "mini !important",
//   },
//   button: {
//     size: "mini !important",
//   },
// };
new Vue({
  el: "#app",
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  }
});