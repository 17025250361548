import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger';
import LangSelect from '@/components/LangSelect';
import db from '@/utils/localstorage';
import Screenfull from '@/components/Screenfull';
import Search from '@/components/HeaderSearch';
import axios from 'axios';
import { getToken } from '@/utils/auth';
import Navbar from "./Sidebar/index.vue";
import UserView from '@/views/common/system/mail/Detail.vue';
var timer = null;
export default {
  components: {
    // Breadcrumb,
    Hamburger: Hamburger,
    LangSelect: LangSelect,
    Screenfull: Screenfull,
    Search: Search,
    Navbar: Navbar,
    UserView: UserView
  },
  computed: {
    sidebar: function sidebar() {
      return this.$store.state.setting.sidebar;
    },
    avatar: function avatar() {
      if (this.$store.state.account.user.avatar.includes('http')) {
        return this.$store.state.account.user.avatar;
      } else {
        return require("@/assets/avatar/".concat(this.$store.state.account.user.avatar));
      }
    },
    username: function username() {
      return this.$store.state.account.user.realName;
    },
    device: function device() {
      return this.$store.state.setting.device;
    }
  },
  data: function data() {
    return {
      messageList: [],
      userViewVisible: false,
      messageNum: 0,
      detailInfo: {},
      mineMessage: []
    };
  },
  created: function created() {
    this.initMessageList();
  },
  mounted: function mounted() {
    // timer = setInterval(this.initMessageList, 60 *1000);
  },
  beforeDestroy: function beforeDestroy() {
    // clearInterval(timer);
  },
  methods: {
    viewClose: function viewClose() {
      this.userViewVisible = false;
      this.initMessageList();
    },
    enter: function enter() {
      this.$router.push({
        path: '/system/mail'
      });
    },
    updateMessage: function updateMessage(row) {
      this.userViewVisible = true;
      this.detailInfo = this.mineMessage.filter(function (i) {
        return i.id == row.id;
      })[0];
      // console.log( this.detailInfo,' this.detailInfo')
      // this.$get(`system/sys-msg/read/${row.id}`).then(() => {
      //   this.$message({
      //     message: this.$t('tips.Success'),
      //     type: 'success'
      //   })
      // })
      // this.initMessageList()
    },
    initMessageList: function initMessageList() {
      var _this = this;
      this.$get('system/sys-msg/querByToken', {
        pageSize: 999,
        pageNum: 1
      }).then(function (r) {
        var datas = r.data.data;
        _this.mineMessage = datas.records;
        _this.messageList = datas.records.filter(function (i) {
          return i.userRead == false;
        });
        _this.messageNum = _this.messageList.length;
      });
    },
    setting: function setting() {
      this.$store.commit('setting/openSettingBar', true);
    },
    logout: function logout() {
      var _this2 = this;
      axios.post("".concat(process.env.VUE_APP_BASE_API, "auth/signout"), {}, {
        headers: {
          'Authorization': 'bearer ' + getToken()
        }
      }).then(function (res) {
        if (res.data.code === 200) {
          _this2.clean();
        }
      });
    },
    clean: function clean() {
      db.clear();
      this.$store.commit('account/clearRouter');
      // location.reload()
      this.$router.push('/login');
    },
    deleteCache: function deleteCache() {
      this.$confirm(this.$t('tips.confirmDeleteCache'), this.$t('common.tips'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(function () {
        db.remove('USER_ROUTER');
        // db.remove('PERMISSIONS')
        db.remove('DASHBOARD_PERMISSIONS');
        location.reload();
      }).catch(function () {
        // do nothing
      });
    }
  }
};